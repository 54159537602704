
h1 {
    background: #fff;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: var(--arabic-font);
    font-size: 185px;
    font-weight: 800;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*text-shadow: 6px 5px 7px #c7badc;*/
    letter-spacing: 5px;
  }
  h1 span {
    width: 100%;
    position: relative;
    text-align: center;
  }
  h1 span:before {
    background: linear-gradient(45deg, #fc5c7d, #b98772, #fc5c7d);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    content: "";
    mix-blend-mode: screen;
  }
  
  .loader-wrapper {
    height: 100vh;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #ffffff;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    z-index: 9;
    top: 0;
  }
  
  .loader {
    border: 16px solid #111;
    border-radius: 50%;
    border-top: 16px double #b98772;
    border-bottom: 16px double #b98772;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  /*==============
    [01] Page css
  =================*/
  .page-section .content-detail {
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .page-section .content-detail .sub-title, .page-section .content-detail .detail-text {
    display: block;
    color: #777;
    text-align: center;
  }
  .page-section .content-detail .sub-title {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 30px;
    color: #555;
  }
  .page-section .content-detail .global-title {
    display: block;
    color: #5bc86d;
    font-size: 150px;
    font-weight: 800;
  }
  .page-section .content-detail .global-title span {
    -webkit-animation: animateblur 1s linear forwards;
            animation: animateblur 1s linear forwards;
  }
  .page-section .content-detail .global-title span:nth-child(1) {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  .page-section .content-detail .global-title span:nth-child(2) {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
  }
  .page-section .content-detail .global-title span:nth-child(3) {
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
  }
  @-webkit-keyframes animateblur {
    0% {
      opacity: 0;
      -webkit-filter: blur(10px);
              filter: blur(10px);
    }
    100% {
      opacity: 1;
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }
  @keyframes animateblur {
    0% {
      opacity: 0;
      -webkit-filter: blur(10px);
              filter: blur(10px);
    }
    100% {
      opacity: 1;
      -webkit-filter: blur(0px);
              filter: blur(0px);
    }
  }
  .page-section .content-detail .back-btn {
    margin-top: 15px;
  }
  .page-section .content-detail .back-btn .btn {
    padding: 8px 15px;
    color: #fff;
    border-radius: 30px;
    font-size: 14px;
    background: #8d6dbd;
    -webkit-box-shadow: 0px 5px 10px #a98ad8;
            box-shadow: 0px 5px 10px #a98ad8;
  }
  .page-section .content-detail .back-btn .btn:hover {
    background: #a98ad8;
    color: #fff;
    -webkit-box-shadow: 0px 5px 10px #cab6e8;
            box-shadow: 0px 5px 10px #cab6e8;
  }
  .page-section .content-detail .container-txt {
    width: 415px;
    height: 250px;
    top: 0;
    left: 0;
    position: relative;
  }
  .page-section .content-detail .container-txt div {
    display: block;
    position: absolute;
  }
  .page-section .content-detail .container-txt .blobs_1 {
    background: #c5006b;
    width: 62px;
    height: 62px;
    top: 58px;
    left: 210px;
    mix-blend-mode: color;
    -webkit-animation: circlemoves 15s ease-in-out infinite alternate;
            animation: circlemoves 15s ease-in-out infinite alternate;
  }
  .page-section .content-detail .container-txt .blobs_2 {
    background: #ff6c36;
    width: 78px;
    height: 78px;
    top: 155px;
    left: 230px;
    mix-blend-mode: color;
    -webkit-animation: circlemoves 15s ease-in-out infinite alternate;
            animation: circlemoves 15s ease-in-out infinite alternate;
  }
  .page-section .content-detail .container-txt .blobs_3 {
    background: #03b903;
    width: 63px;
    height: 63px;
    top: 145px;
    left: 20px;
    mix-blend-mode: color;
    -webkit-animation: circlemoves 15s ease-in-out infinite alternate;
            animation: circlemoves 15s ease-in-out infinite alternate;
  }
  .page-section .content-detail .container-txt .blobs_4 {
    background: #900101;
    width: 95px;
    height: 95px;
    top: 115px;
    left: 100px;
    mix-blend-mode: color;
    -webkit-animation: circlemoves 15s ease-in-out infinite alternate;
            animation: circlemoves 15s ease-in-out infinite alternate;
  }
  .page-section .content-detail .container-txt .blobs_5 {
    background: #795548;
    width: 48px;
    height: 48px;
    top: 55px;
    left: 70px;
    mix-blend-mode: color;
    -webkit-animation: circlemoves 15s ease-in-out infinite alternate;
            animation: circlemoves 15s ease-in-out infinite alternate;
  }
  .page-section .content-detail .container-txt .blobs_6 {
    background: #00b5b5;
    width: 66px;
    height: 66px;
    top: 133px;
    right: 44px;
    mix-blend-mode: color;
    -webkit-animation: circlemoves 15s ease-in-out infinite alternate;
            animation: circlemoves 15s ease-in-out infinite alternate;
  }
  .page-section .content-detail .container-txt .blobs_7 {
    background: #ff8c00;
    width: 52px;
    height: 52px;
    top: 25px;
    left: 336px;
    mix-blend-mode: color;
    -webkit-animation: circlemoves 15s ease-in-out infinite alternate;
            animation: circlemoves 15s ease-in-out infinite alternate;
  }
  @-webkit-keyframes circlemoves {
    0% {
      border-radius: 25% 69% 58% 42%/52% 72% 65% 56%;
    }
    10% {
      border-radius: 75% 28% 50% 48%/70% 44% 55% 29%;
    }
    20% {
      border-radius: 47% 53% 29% 67%/30% 40% 59% 69%;
    }
    30% {
      border-radius: 72% 25% 65% 45%/33% 63% 33% 65%;
    }
    40% {
      border-radius: 63% 35% 55% 43%/25% 25% 75% 75%;
    }
    50% {
      border-radius: 35% 63% 73% 33%/63% 25% 73% 35%;
    }
    60% {
      border-radius: 25% 75% 25% 73%/75% 53% 45% 25%;
    }
    70% {
      border-radius: 38% 59% 63% 32%/72% 62% 32% 24%;
    }
    80% {
      border-radius: 53% 44% 36% 62%/35% 32% 72% 60%;
    }
    90% {
      border-radius: 24% 74% 72% 32%/40% 49% 49% 60%;
    }
    100% {
      border-radius: 60% 29% 29% 65%/68% 75% 30% 32%;
    }
  }
  @keyframes circlemoves {
    0% {
      border-radius: 25% 69% 58% 42%/52% 72% 65% 56%;
    }
    10% {
      border-radius: 75% 28% 50% 48%/70% 44% 55% 29%;
    }
    20% {
      border-radius: 47% 53% 29% 67%/30% 40% 59% 69%;
    }
    30% {
      border-radius: 72% 25% 65% 45%/33% 63% 33% 65%;
    }
    40% {
      border-radius: 63% 35% 55% 43%/25% 25% 75% 75%;
    }
    50% {
      border-radius: 35% 63% 73% 33%/63% 25% 73% 35%;
    }
    60% {
      border-radius: 25% 75% 25% 73%/75% 53% 45% 25%;
    }
    70% {
      border-radius: 38% 59% 63% 32%/72% 62% 32% 24%;
    }
    80% {
      border-radius: 53% 44% 36% 62%/35% 32% 72% 60%;
    }
    90% {
      border-radius: 24% 74% 72% 32%/40% 49% 49% 60%;
    }
    100% {
      border-radius: 60% 29% 29% 65%/68% 75% 30% 32%;
    }
  }