.Report-incident {
    font-family: 'primary-font-500';
    background-color: var(--color4);
    height: 337px;
    font-size: 17px;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        color: var(--color11);
    }

    button {
        cursor: pointer;

        &:hover {
            transform: scale(.97);
        }

        &:active {
            transform: scale(.92);
        }
    }

    .banner {
        height: inherit;
        position: relative;
        overflow: hidden;

        img {
            height: inherit;
            object-fit: cover;
        }

        .banner-content {
            position: absolute;
            bottom: 50px;
            width: inherit;

            h1 {
                font-size: 30px;
                color: var(--color1);
                margin-bottom: 2rem;
                font-weight: 700;
            }

            p.incident-number {
                color: white;
                font-size: 1.3rem;
            }

            .form-page-progress {
                display: flex;
                width: inherit;
                padding-left: 25px;

                .stages {
                    display: flex;
                    flex-direction: column;
                    width: 40px;
                    align-items: center;

                    i {
                        background-color: var(--color6);
                        border-radius: 50%;
                        width: 40px;
                        aspect-ratio: 1 / 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: .8rem;
                        cursor: pointer;
                        font-style: normal;
                        font-weight: bold;
                        user-select: none;
                        color: var(--color1);

                        &.done {
                            background-color: var(--color5);
                        }
                    }

                    p {
                        white-space: nowrap;
                        text-align: center;
                        margin-bottom: 0;
                        cursor: pointer;
                        font-weight: 700;
                        color: var(--color1);
                    }

                    @media screen and (max-width:570px) {
                        font-size: 15px;
                    }
                }

                .progress-line {
                    display: block;
                    width: 200px;
                    border-top: 2px dashed white;
                    position: relative;
                    top: 20px;

                    &.done {
                        border-color: var(--color5);
                    }

                    @media screen and (max-width:940px) {
                        width: 150px;
                    }

                    @media screen and (max-width:740px) {
                        width: 100px;
                    }

                    @media screen and (max-width:570px) {
                        width: 80px;
                    }
                }
            }
        }
    }

    .Page1 {

        .content {
            margin-top: 70px;
            font-size: 17px;

            article {
                margin-bottom: 40px;

                h2 {
                    font-weight: 700;
                }
            }
        }

        .form-fill-up {
            display: flex;
            justify-content: space-between;
            padding-right: 50px;
            font-size: 17px;
            margin-top: 50px;

            .next-button {
                margin-top: 0;

                &.disabled {
                    border-color: var(--color7);
                    color: var(--color7);

                    img {
                        filter: invert(71%) sepia(87%) saturate(1%) hue-rotate(346deg) brightness(96%) contrast(93%);
                    }
                }
            }

            label {
                display: flex;
                cursor: pointer;
                height: 50px;
                align-items: flex-start;

                input {
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                    position: relative;
                    top: -4px;

                    &:checked {
                        accent-color: var(--color10);
                    }
                }
            }

            @media screen and (max-width:760px) {
                flex-direction: column;

                .button-div {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 50px;
                }
            }
        }
    }

    .Page2 {
        .container {
            padding: 0;
        }

        h2 {
            margin-top: 40px;
            margin-bottom: 25px;
            font-weight: 700;
        }

        h2~p {
            margin: 0;
            color: red;
            font-weight: 600;
            letter-spacing: 1px;
        }

        .personal-info-form {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;

            @media screen and (max-width:700px) {
                grid-template-columns: 1fr;
            }

            .phone {
                width: 100%;
                display: grid;
                grid-template-columns: 14% 85%;
                gap: 1%;

                @media screen and (max-width:1200px) {
                    grid-template-columns: 19% 80%;
                }

                @media screen and (max-width:900px) {
                    grid-template-columns: 24% 75%;
                }

                @media screen and (max-width:800px) {
                    grid-template-columns: 29% 70%;
                }

                @media screen and (max-width:700px) {
                    grid-template-columns: 19% 80%;
                }
            }
        }

        .send-otp {
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;

            button {
                border: none;
                background-color: var(--color5);
                color: white;
                padding: 8px 15px;
                border-radius: 4px;
            }

        }

        .enter-otp {
            width: 500px;

            @media screen and (max-width:800px) {
                margin-top: 70px;
                margin-bottom: 70px;
            }

            P {
                font-weight: bold;

                &.label {
                    margin-bottom: 10px;
                }
            }

            .otp-input {
                .otp-resend {
                    width: 80%;

                }

                input {
                    width: 35px !important;
                    height: 35px !important;
                    border-radius: 4px;
                    border: 1px solid black;

                    &::selection {
                        background-color: inherit;
                    }

                    &:focus {
                        outline: none;
                    }

                }

                button {
                    border: none;
                    border-radius: 4px;
                    background-color: var(--color7-dark);
                    color: white;
                    padding: 5px 15px;
                }
            }

        }

        .buttons {
            display: flex;
            justify-content: flex-end;
        }
    }

    .input-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        p {
            margin: 0;
            color: red;
            font-weight: 600;
            letter-spacing: 1px;
        }

        input,
        select {
            border: none;
            background-color: var(--color7-light);
            padding: 10px 20px;
            width: 100%;
            height: 50px;

            &.error {
                color: red;

                &::placeholder {
                    color: red;
                }
            }

            &:focus {
                outline: none;
            }
        }
    }


    .Page3 {
        h2 {
            margin-bottom: 30px;
            margin-top: 50px;
            font-weight: 700;
        }

        .inputs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 15px;

            select {
                appearance: none;
            }

            input,
            textarea {
                border: none;
                background-color: var(--color7-light);
                padding: 10px 20px;

                &:focus {
                    outline: none;
                }
            }

            .radio-question {
                display: flex;
                align-items: center;
                width: 80%;
                height: 50px;

                .labels {
                    label {
                        margin-left: 20px;
                    }

                    input {
                        width: 20px;
                        height: 20px;
                        margin-left: 5px;
                        position: relative;
                        top: 3px;
                        accent-color: var(--color10);
                    }
                }
            }

            .input-group.textarea {
                grid-column: 1 / 3;

                textarea {
                    resize: none;

                    &.error {
                        color: red;

                        &::placeholder {
                            color: red;
                        }
                    }
                }
            }

            .max-info {
                font-size: 14px;
                color: #777171;

                img {
                    width: 15px;
                    margin-right: 5px;
                    filter: invert(48%) sepia(7%) saturate(215%) hue-rotate(314deg) brightness(91%) contrast(91%);
                    position: relative;
                    top: -1px;
                }

            }
        }
    }

    .Page4 {
        &>section {
            padding: 20px;
        }

        h3 {
            margin-bottom: 30px;
            font-weight: bold;
        }

        .attachment-files {
            padding: 20px;

            .file-container {
                position: relative;

                .file {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    margin-right: 25px;
                    margin-bottom: 25px;
                    background-color: var(--color7);
                    border-radius: 5px;
                }

                .file-name {
                    display: inline;
                    font-size: 12pt;
                    width: 6ch;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: space-between;

            .confirmation {
                display: flex;
                align-items: center;
                order: 2;

                input {
                    margin-right: 15px;
                    width: 18px;
                    height: 18px;
                    accent-color: var(--color10);
                    position: relative;
                    top: -1px;
                }
            }

            .buttons-group {
                display: contents;

                button:first-of-type {
                    order: 1;
                }

                button:last-of-type {
                    order: 3;
                }
            }
        }
    }

    .Page4,
    .UpdatesPage {
        .personal-info {
            p {
                margin: 0;
            }

            .row1 {
                display: flex;

                .column {
                    display: flex;

                    p {
                        margin-right: 50px;

                        &:first-of-type {
                            font-weight: bold;
                        }
                    }

                    &:last-of-type {
                        padding-left: 50px;
                        border-left: 1px solid var(--color7);
                    }
                }
            }
        }

        .incident-repo {
            p {
                margin: 0;
            }

            .tab {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .row2 {
                    display: flex;
                    gap: 20px;

                    .column {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                    }
                }

                .column,
                .row3 {
                    p:first-of-type {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .Page3,
    .UpdatesPage {
        .attachment-files {
            padding: 20px;

            .file-container {
                position: relative;

                .file {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    margin-right: 25px;
                    margin-bottom: 25px;
                    background-color: var(--color7);
                    border-radius: 5px;
                }

                .file-close {
                    position: absolute;
                    top: -30px;
                    left: 38px;
                    cursor: pointer;
                }

                .file-name {
                    display: inline;
                    font-size: 12pt;
                    width: 6ch;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }

        .buttons {
            display: flex;
            justify-content: space-between;

            .attachment {
                background-color: var(--color4);
                border: 1px solid var(--color4);
                color: white;
                border-radius: 4px;
                padding: 10px 20px;
                cursor: pointer;

                img {
                    margin-right: 15px;
                    position: relative;
                    top: -3px;
                }
            }
        }
    }

    .ThanksPage,
    .LinkExpired {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7px;
        height: 400px;

        img.tick,
        img.wrong {
            width: 75px;
            margin-bottom: 15px;
        }

        h2 {
            font-weight: bold;
            margin: 0;
        }

        p {
            margin: 0;
        }
    }

    .UpdatesPage {

        h2 {
            margin-bottom: 0;
            font-weight: bold;
        }

        .report-details {
            background-color: var(--color12);
            padding: 45px;
            border-radius: 10px;
            margin-top: 35px;


            .personal-info {
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }

        .reply {
            padding: 40px 45px 0;

            h2 {
                font-size: 24px;
                margin-bottom: 15px;
            }

            p {
                color: var(--color13);
            }
        }

        .attachment-files {
            padding-left: 45px;
        }

        .buttons {
            padding-left: 45px;
        }
    }

    footer {
        display: flex;
        justify-content: space-between;
        color: #7f7f7f;
        margin-top: 100px;

        ul {
            list-style: none;
            padding: 0;
            display: flex;
            gap: 20px;

            i {
                color: #7f7f7f;
            }
        }

        @media screen and (max-width:760px) {
            flex-direction: column;
            align-items: center;
        }
    }
}

.next-button {
    background-color: inherit;
    border: 1px solid var(--color4);
    border-radius: 4px;
    padding: 10px 20px;

    img.right {
        margin-left: 15px;
    }

    img.left {
        margin-right: 15px;

        &.arrow {
            transform: rotate(180deg);
        }
    }
}

.thank-you-modal {
    color: green;

    .modal-dialog {
        margin-top: 100px;
        display: flex;
        justify-content: center;
    }

    .modal-content {
        flex-shrink: 0;
        width: 800px;
        height: 500px;
        border-radius: 31px;

        .modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 7px;

            img.tick {
                width: 75px;
                margin-bottom: 15px;
            }

            h2 {
                font-weight: bold;
                margin: 0;
            }

            button {
                cursor: pointer;

                &:hover {
                    transform: scale(.97);
                }

                &:active {
                    transform: scale(.92);
                }
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .Report-incident {
        .pages {
            .container {
                width: 1000px;
            }

            .Page3 {
                .radio-question {
                    width: 90%;
                }
            }
        }
    }
}

@media screen and (max-width:1100px) {
    .Report-incident {
        .pages {
            .container {
                width: 900px;
            }

            .Page3 {
                .radio-question {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width:1000px) {
    .Report-incident {
        .pages {
            .container {
                width: 800px;
            }

            .Page3 {
                .radio-question {
                    flex-direction: column;
                    align-items: flex-start;

                    .labels {
                        padding-left: 200px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:900px) {
    .Report-incident {
        .pages {
            .container {
                width: 700px;
            }

            .Page1 {
                .form-fill-up {

                    label {
                        width: 350px;
                    }
                }
            }

            .Page3 {
                .radio-question {
                    .labels {
                        padding-left: 150px;
                    }
                }
            }

            .Page4,
            .UpdatesPage {
                .personal-info {

                    .row1 {
                        .column {
                            p {
                                margin-right: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:800px) {
    .Report-incident {
        .pages {
            .container {
                width: 600px;
            }

            .Page3 {
                .radio-question {
                    .labels {
                        padding-left: 100px;
                    }
                }
            }

            .Page4,
            .UpdatesPage {
                .personal-info {

                    .row1 {
                        flex-direction: column;
                        gap: 20px;

                        .column {
                            border: none;
                            padding: 0;

                            &>div {
                                width: 200px;
                            }
                        }
                    }
                }

                .buttons {
                    flex-direction: column;
                    gap: 45px;

                    .confirmation {
                        order: 1;
                    }

                    .buttons-group {
                        order: 2;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:700px) {
    .Report-incident {
        .pages {
            .container {
                width: 500px;
            }

            .Page3 {
                .inputs {
                    // grid-template-columns: 1fr;
                    display: flex;
                    flex-direction: column;
                }

                .radio-question {
                    flex-direction: row;
                    align-items: center;

                    .labels {
                        padding-left: 50px;
                    }

                }
            }

            .Page4,
            .UpdatesPage {
                .incident-repo {
                    .row2 {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    .Report-incident {
        .pages {
            .container {
                width: 450px;
            }

            .Page3 {
                .radio-question {
                    .labels {
                        padding-left: 0px;
                    }

                }
            }
        }
    }
}

@media screen and (max-width:500px) {
    .Report-incident {
        .pages {
            .container {
                width: 400px;
            }

            .Page3 {
                .radio-question {
                    flex-direction: column;
                    align-items: flex-start;

                    .labels {
                        padding-left: 150px;
                    }
                }
            }

            .Page4,
            .UpdatesPage {
                .personal-info {
                    padding-right: 5px;
                }
            }
        }
    }
}