.economic__banner  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 50px;
    border-radius: 0;
    height: 5px;
    background: #6f5b5f;
}

.economic__banner .swiper-pagination-clickable .swiper-pagination-bullet{
    width: 50px;
    border-radius: 0;
    height: 5px;
    background: #fff;   
}
.economic__banner .swiper-pagination {
    position: absolute;
    text-align: left;
    transition: .3s opacity;
    transform: translate3d(0,0,0);
    z-index: 10;
    bottom: 32% !important;
} 
.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1140px !important;
}  
.economic__banner .navigation__default{
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 20px;
}
.economic__banner .navigation__default i {
    margin: 0 5px;
}

.bg-default{
    background-color: #F8F8F8;
}

.form-control {
    /* height: 46px !important; */
    padding: 0.60rem !important;
    border: none !important;
    border-bottom: 1px solid #ced4da !important;
    border-radius: 0 !important;
    padding-left: 0 !important;
    position: relative;
    margin-bottom: 1rem;
}
/* textarea.form-control{
    resize: none;
    overflow: hidden;
    min-height: 30px;
} */
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
    box-shadow: inset 0 -1px 0 transparent;
    border-bottom: 1px solid #ced4da;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}
a{
    text-decoration: none !important;
}

.btn-primary {
    background-color: #B98772 !important;
    border-color: #B98772 !important;
    border-radius: 0px !important;
    margin-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 107px;
}

.col-md-3.col-sm-68 {
    padding-left: 0;
}